import React from 'react';
import { Paper, Typography, useTheme } from '@mui/material';
import { useSalary } from '../context/SalaryContext';

export function YearNote() {
    const { selectedYear, TAX_CONFIG, formatPara, currentLanguage } = useSalary();
    const theme = useTheme();
    const currentConfig = TAX_CONFIG[selectedYear];

    if (!currentConfig.yearNote || !currentConfig.yearNoteText) {
        return null;
    }

    const noteText = currentConfig.yearNoteText[currentLanguage] || currentConfig.yearNoteText['tr'];
    const formattedText = noteText
        .replace('{asgariUcret}', formatPara(currentConfig.asgariUcret))
        .replace('{sgkTavanUcret}', formatPara(currentConfig.sgkTavanUcret));

    return (
        <Paper 
            elevation={1}
            sx={{ 
                padding: 3,
                borderRadius: 1,
                backgroundColor: theme.palette.mode === 'light' 
                    ? theme.palette.warning.light 
                    : theme.palette.warning.dark,
                color: theme.palette.mode === 'light'
                    ? theme.palette.warning.dark
                    : theme.palette.warning.contrastText
            }}
        >
            <Typography variant="body1">
                {formattedText}
            </Typography>
        </Paper>
    );
} 