import React from 'react';
import { 
  Accordion, 
  AccordionSummary, 
  AccordionDetails, 
  Typography,
  Paper,
  Box 
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSalary } from '../context/SalaryContext';

export function FAQ() {
  const { t, selectedYear } = useSalary();

  const faqs = [
    {
      question: t('faq.netSalaryCalc.question', { year: selectedYear }),
      answer: t('faq.netSalaryCalc.answer', { year: selectedYear })
    },
    {
      question: t('faq.taxBrackets.question', { year: selectedYear }),
      answer: t('faq.taxBrackets.answer', { year: selectedYear })
    },
    {
      question: t('faq.minimumWage.question', { year: selectedYear }),
      answer: t('faq.minimumWage.answer', { year: selectedYear })
    },
    {
      question: t('faq.sgkPremium.question'),
      answer: t('faq.sgkPremium.answer')
    },
    {
      question: t('faq.stampTax.question'),
      answer: t('faq.stampTax.answer')
    }
  ];

  return (
    <Paper 
      elevation={1}
      sx={{ 
        padding: 3,
        borderRadius: 1
      }}
    >
      <Typography 
        variant="h6" 
        component="h2" 
        gutterBottom
        sx={{ mb: 3 }}
      >
        {t('faq.title')}
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {faqs.map((faq, index) => (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`faq-content-${index}`}
              id={`faq-header-${index}`}
            >
              <Typography>{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ whiteSpace: 'pre-line' }}>
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Paper>
  );
} 