import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

const formatStyles = {
  auto: {
    minHeight: '100px',
    width: '100%'
  },
  rectangle: {
    minHeight: '280px',
    maxWidth: '336px'
  },
  horizontal: {
    minHeight: '90px',
    maxWidth: '728px'
  },
  vertical: {
    minHeight: '600px',
    maxWidth: '160px'
  }
};

export function AdUnit({ slot, format = 'auto', responsive = true, isEnabled = true }) {
  const [adLoaded, setAdLoaded] = useState(false);

  useEffect(() => {
    if (!isEnabled) return;

    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
      setAdLoaded(true);
    } catch (error) {
      console.error('AdSense error:', error);
      setAdLoaded(false);
    }
  }, [isEnabled]);

  if (!isEnabled || !adLoaded) {
    return null;
  }

  const currentStyles = formatStyles[format] || formatStyles.auto;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        my: 2,
        ...(format === 'auto' ? {} : currentStyles),
        overflow: 'hidden'
      }}
    >
      <ins
        className="adsbygoogle"
        style={{
          display: 'block',
          width: '100%',
          height: format === 'auto' ? 'auto' : '100%',
          ...(format === 'auto' ? {} : {
            minHeight: currentStyles.minHeight,
            maxWidth: currentStyles.maxWidth
          }),
          textAlign: 'center'
        }}
        data-ad-client="ca-pub-6953434971405282"
        data-ad-slot={slot}
        data-ad-format={format}
        data-full-width-responsive={responsive}
      />
    </Box>
  );
} 