export const TAX_CONFIG = {
    "2025": {
        "vergiDilimleri": [
            { "limit": 158000, "oran": 0.15 },
            { "limit": 330000, "oran": 0.20 },
            { "limit": 1250000, "oran": 0.27 },
            { "limit": 4300000, "oran": 0.35 },
            { "limit": null, "oran": 0.40 }
        ],
        "sgkTavanUcret": 215922.2,
        "asgariUcret": 28789.60,
        "katsayilar": {
            "sgkOrani": 0.14,
            "issizlikSigortasiOrani": 0.01,
            "damgaVergisiOrani": 0.00759
        },
        "yearNote": true,
        "yearNoteText": {
            "tr": "2025 yılı gelir vergisi dilimleri, SGK tavan ücreti ({sgkTavanUcret} TL) ve asgari ücret ({asgariUcret} TL) tutarları henüz kesinleşmemiştir. SGK tavan ücreti ve asgari ücret tutarları, 2024 yılı yeniden değerleme oranı olan %43,93 ile çarpılarak hesaplanmıştır. Hesaplamalar tahmini değerler üzerinden yapılmaktadır. Bu değerler kesinleştiği zaman sitemizde güncellenecektir.",
            "en": "Income tax brackets, SGK ceiling amount ({sgkTavanUcret} TRY) and minimum wage ({asgariUcret} TRY) for 2025 have not been finalized yet. SGK ceiling and minimum wage amounts are calculated by applying the 2024 revaluation rate of 43.93%. Calculations are based on estimated values. These values will be updated on our website once they are finalized.",
            "ar": "لم يتم تحديد شرائح ضريبة الدخل والحد الأقصى للضمان الاجتماعي ({sgkTavanUcret} ليرة تركية) والحد الأدنى للأجور ({asgariUcret} ليرة تركية) لعام 2025 بعد. تم حساب الحد الأقصى للضمان الاجتماعي والحد الأدنى للأجور باستخدام معدل إعادة التقييم لعام 2024 البالغ 43.93٪. تستند الحسابات إلى قيم تقديرية. سيتم تحديث هذه القيم على موقعنا بمجرد الانتهاء منها."
        }
    },
    "2024": {
        "vergiDilimleri": [
            { "limit": 110000, "oran": 0.15 },
            { "limit": 230000, "oran": 0.20 },
            { "limit": 870000, "oran": 0.27 },
            { "limit": 3000000, "oran": 0.35 },
            { "limit": null, "oran": 0.40 }
        ],
        "sgkTavanUcret": 150000,
        "asgariUcret": 20002.50,
        "katsayilar": {
            "sgkOrani": 0.14,
            "issizlikSigortasiOrani": 0.01,
            "damgaVergisiOrani": 0.00759
        }
    }
}; 