import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Box,
    Divider
} from '@mui/material';
import { useSalary } from '../context/SalaryContext';

export function PrivacyPolicy({ open, onClose }) {
    const { t } = useSalary();

    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: 2,
                    minHeight: '80vh'
                }
            }}
        >
            <DialogTitle sx={{ 
                borderBottom: 1, 
                borderColor: 'divider',
                pb: 2
            }}>
                <Typography variant="h5" component="div">
                    {t('privacy.title')}
                </Typography>
                <Typography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block' }}>
                    {t('privacy.lastUpdated')}
                </Typography>
            </DialogTitle>

            <DialogContent>
                <Box sx={{ my: 3 }}>
                    <Typography variant="h6" gutterBottom>
                        {t('privacy.introduction')}
                    </Typography>
                    <Typography paragraph>
                        {t('privacy.introductionText')}
                    </Typography>

                    <Divider sx={{ my: 3 }} />

                    <Typography variant="h6" gutterBottom>
                        {t('privacy.dataCollection')}
                    </Typography>
                    <Typography paragraph>
                        <strong>{t('privacy.salaryCalculations')}:</strong> {t('privacy.salaryCalculationsText')}
                    </Typography>
                    <Typography paragraph>
                        <strong>{t('privacy.personalData')}:</strong> {t('privacy.personalDataText')}
                    </Typography>

                    <Divider sx={{ my: 3 }} />

                    <Typography variant="h6" gutterBottom>
                        {t('privacy.cookiesAndAnalytics')}
                    </Typography>
                    <Typography paragraph>
                        <strong>{t('privacy.necessaryCookies')}:</strong> {t('privacy.necessaryCookiesText')}
                    </Typography>
                    <Typography paragraph>
                        <strong>{t('privacy.analyticCookies')}:</strong> {t('privacy.analyticCookiesText')}
                    </Typography>

                    <Divider sx={{ my: 3 }} />

                    <Typography variant="h6" gutterBottom>
                        {t('privacy.security')}
                    </Typography>
                    <Typography paragraph>
                        {t('privacy.securityText')}
                    </Typography>

                    <Divider sx={{ my: 3 }} />

                    <Typography variant="h6" gutterBottom>
                        {t('privacy.contact')}
                    </Typography>
                    <Typography paragraph>
                        {t('privacy.contactText')}
                    </Typography>
                </Box>
            </DialogContent>

            <DialogActions sx={{ borderTop: 1, borderColor: 'divider', p: 2 }}>
                <Button onClick={onClose} variant="contained" size="large">
                    {t('privacy.close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
} 