import { useState } from 'react';
import { Tooltip } from '@mui/material';

export const CustomTooltip = ({ children, title, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Tooltip
      {...props}
      title={title}
      open={isOpen}
      onTouchStart={() => setIsOpen(true)}
      onTouchEnd={() => {
        setTimeout(() => setIsOpen(false), 3000); // 3 saniye göster
      }}
      onClick={() => {
        setIsOpen(true);
        setTimeout(() => setIsOpen(false), 3000); // 3 saniye göster
      }}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: 'rgba(0, 0, 0, 0.9)',
            color: 'white',
            fontSize: '14px',
            padding: '12px',
            lineHeight: '1.8',
            whiteSpace: 'pre-line',
            maxWidth: 'none',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
          }
        },
        ...props.componentsProps
      }}
    >
      {children}
    </Tooltip>
  );
}; 