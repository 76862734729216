import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { useTheme } from '../context/ThemeContext';

export function ThemeToggle() {
    const { mode, toggleTheme } = useTheme();

    return (
        <Tooltip title={mode === 'light' ? 'Dark Mode' : 'Light Mode'}>
            <IconButton
                onClick={toggleTheme}
                color="inherit"
                sx={{
                    transition: 'transform 0.3s ease-in-out',
                    '&:hover': {
                        transform: 'rotate(30deg)',
                    },
                }}
            >
                {mode === 'light' ? <DarkModeIcon /> : <LightModeIcon />}
            </IconButton>
        </Tooltip>
    );
} 