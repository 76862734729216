import React, { createContext, useContext, useState, useCallback } from 'react';
import { TAX_CONFIG } from '../config/taxConfig';
import { translations } from '../config/translations';

const SalaryContext = createContext();

function getNestedValue(obj, path) {
    return path.split('.').reduce((current, key) => {
        return current ? current[key] : undefined;
    }, obj);
}

export function SalaryProvider({ children }) {
    const [selectedYear, setSelectedYear] = useState("2025");
    const [currentLanguage, setCurrentLanguage] = useState("tr");
    const [monthlyGrossSalaries, setMonthlyGrossSalaries] = useState(new Array(12).fill(0));
    const [isStickyMonthsEnabled, setIsStickyMonthsEnabled] = useState(true);

    const t = useCallback((key, params = {}) => {
        let text = getNestedValue(translations[currentLanguage], key) || getNestedValue(translations['tr'], key);
        if (!text) {
            console.warn(`Translation missing for key: ${key}`);
            return key;
        }
        Object.keys(params).forEach(param => {
            text = text.replace(`{${param}}`, params[param]);
        });
        return text;
    }, [currentLanguage]);

    const formatPara = useCallback((number) => {
        return new Intl.NumberFormat('tr-TR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(number);
    }, []);

    const value = {
        selectedYear,
        setSelectedYear,
        currentLanguage,
        setCurrentLanguage,
        monthlyGrossSalaries,
        setMonthlyGrossSalaries,
        isStickyMonthsEnabled,
        setIsStickyMonthsEnabled,
        t,
        formatPara,
        TAX_CONFIG
    };

    return (
        <SalaryContext.Provider value={value}>
            {children}
        </SalaryContext.Provider>
    );
}

export function useSalary() {
    const context = useContext(SalaryContext);
    if (!context) {
        throw new Error('useSalary must be used within a SalaryProvider');
    }
    return context;
} 