export const translations = {
    tr: {
        title: "Net Maaş Hesaplama",
        subtitle: "Güncel vergi dilimleri ve asgari ücret muafiyeti dahil detaylı hesaplama",
        calculate: "Hesapla",
        grossSalary: "Brüt Maaş",
        grossSalaryPlaceholder: "Aylık brüt maaşınızı giriniz",
        netSalary: "Net Maaş",
        sgkPremium: "SGK Primi",
        unemploymentInsurance: "İşsizlik Sigortası",
        stampTax: "Damga Vergisi",
        incomeTax: "Gelir Vergisi",
        taxBracket: "Vergi Dilimi",
        month: "Ay",
        total: "Toplam",
        average: "Ortalama",
        grossRatio: "Brüt'e Oran",
        taxBrackets: "{year} Gelir Vergisi Dilimleri",
        andAbove: "ve üzeri",
        note: "Not",
        minimumWageNote: "Asgari ücret altında maaş hesaplaması yapılamaz",
        enterValidSalary: "Lütfen geçerli bir maaş giriniz",
        salaryBelowMinimumWage: "Girdiğiniz maaş asgari ücretin ({asgariUcret} TL) altında olamaz",
        ceiling: "Tavan",
        calculatedStampTax: "Hesaplanan Damga Vergisi",
        deductedStampTax: "İndirimli Damga Vergisi",
        calculatedIncomeTax: "Hesaplanan Gelir Vergisi",
        deductedIncomeTax: "İndirimli Gelir Vergisi",
        minimumWageExemption: "Asgari Ücret İstisnası",
        taxBracketDetails: "Vergi Dilimi Detayları",
        fromBracket: "diliminden",
        months: ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"],
        disclaimer: "Bu hesaplama aracı bilgilendirme amaçlıdır. Hesaplamalar yaklaşık değerler üzerinden yapılmaktadır ve gerçek değerlerden farklılık gösterebilir. Özellikle asgari ücret muafiyeti, vergi dilimleri ve SGK tavan ücreti gibi değerler yıl içinde değişiklik gösterebilir. Kesin bilgi için lütfen mali müşavirinize danışınız veya ilgili resmi kurumların (GİB, SGK) web sitelerini ziyaret ediniz. Bu araç üzerinden yapılan hesaplamalar nedeniyle doğabilecek hiçbir zarardan sorumluluk kabul edilmemektedir.",
        privacy: {
            title: "Gizlilik Politikası",
            lastUpdated: "Son Güncelleme: 28 Kasım 2024",
            introduction: "Giriş",
            introductionText: "Net Maaş Hesaplama aracı olarak gizliliğinize önem veriyoruz. Bu gizlilik politikası, sitemizi nasıl kullandığınız ve verilerinizi nasıl koruduğumuz hakkında bilgi vermektedir.",
            dataCollection: "Veri Toplama ve Kullanım",
            salaryCalculations: "Maaş Hesaplamaları",
            salaryCalculationsText: "Girdiğiniz maaş bilgileri ve yapılan tüm hesaplamalar yalnızca tarayıcınızda yerel olarak gerçekleştirilir. Bu bilgiler sunucularımıza gönderilmez veya herhangi bir şekilde saklanmaz.",
            personalData: "Kişisel Veriler",
            personalDataText: "Sitemiz hiçbir kişisel veri toplamamakta ve saklamamaktadır. Tüm işlemler tarayıcınızda yerel olarak gerçekleştirilir.",
            cookiesAndAnalytics: "Çerezler ve Analitik",
            necessaryCookies: "Zorunlu Çerezler",
            necessaryCookiesText: "Sitenin temel işlevlerini yerine getirebilmesi için gerekli olan minimum çerezler kullanılmaktadır.",
            analyticCookies: "Analitik Çerezler",
            analyticCookiesText: "Yalnızca izin vermeniz halinde, siteyi nasıl kullandığınızı anlamamıza yardımcı olan anonim kullanım istatistikleri toplanır. Bu veriler kişisel bilgiler içermez ve sizinle ilişkilendirilemez.",
            security: "Güvenlik",
            securityText: "Tüm hesaplamalar tarayıcınızda yerel olarak yapıldığından, girdiğiniz veriler internet üzerinden iletilmez ve sunucularımızda saklanmaz. Bu, verilerinizin güvenliğini maksimum düzeyde sağlar.",
            contact: "İletişim",
            contactText: "Bu gizlilik politikası hakkında sorularınız varsa, lütfen bizimle iletişime geçin.",
            close: "Kapat"
        },
        faq: {
            title: "Sık Sorulan Sorular",
            netSalaryCalc: {
                question: "{year} yılında net maaş nasıl hesaplanır?",
                answer: "{year} yılı net maaş hesaplaması için brüt maaştan sırasıyla:\n\n" +
                       "1. SGK Primi (%14)\n" +
                       "2. İşsizlik Sigortası (%1)\n" +
                       "3. Gelir Vergisi (dilime göre %15-40)\n" +
                       "4. Damga Vergisi (%0.759)\n\n" +
                       "kesintileri yapılır. Asgari ücret muafiyeti de hesaplamaya dahil edilir."
            },
            taxBrackets: {
                question: "{year} vergi dilimleri nelerdir?",
                answer: "{year} yılı gelir vergisi dilimleri şu şekildedir:\n\n" +
                       "• 0-70.000 TL arası: %15\n" +
                       "• 70.000-150.000 TL arası: %20\n" +
                       "• 150.000-550.000 TL arası: %27\n" +
                       "• 550.000 TL üzeri: %35"
            },
            minimumWage: {
                question: "{year} asgari ücret muafiyeti nedir?",
                answer: "{year} yılında asgari ücret tutarı kadar olan kısım gelir vergisinden muaf tutulmaktadır. Bu muafiyet sayesinde asgari ücret tutarına kadar olan kısım için gelir vergisi hesaplanmaz."
            },
            sgkPremium: {
                question: "SGK primi nedir ve nasıl hesaplanır?",
                answer: "SGK primi, çalışanın brüt maaşının %14'ü oranında kesilen ve sosyal güvenlik sistemine aktarılan zorunlu bir kesintedir. SGK tavanını aşan maaşlarda tavan üstü kısım için kesinti yapılmaz."
            },
            stampTax: {
                question: "Damga vergisi nedir?",
                answer: "Damga vergisi, ücret ödemelerinden %0.759 oranında kesilen yasal bir vergidir. Brüt maaş üzerinden hesaplanır ve her ay düzenli olarak kesilir."
            }
        },
        initialTableContent: {
            description: "Net maaş hesaplama aracımız, brüt maaşınızdan yapılacak tüm kesintileri ve vergi dilimlerini otomatik olarak hesaplar. Yukarıdaki alana brüt maaşınızı girerek detaylı hesaplamayı başlatabilirsiniz.",
            features: "Özellikler",
            feature1: "SGK primi, işsizlik sigortası ve damga vergisi hesaplamaları",
            feature2: "Kümülatif vergi dilimi hesaplaması",
            feature3: "Asgari ücret muafiyeti dahil güncel hesaplamalar",
            feature4: "Aylık ve yıllık bazda detaylı maaş analizi"
        }
    },
    en: {
        title: "Net Salary Calculator",
        subtitle: "Detailed calculation including current tax brackets and minimum wage exemption",
        calculate: "Calculate",
        grossSalary: "Gross Salary",
        grossSalaryPlaceholder: "Enter your monthly gross salary",
        netSalary: "Net Salary",
        sgkPremium: "Social Security Premium",
        unemploymentInsurance: "Unemployment Insurance",
        stampTax: "Stamp Tax",
        incomeTax: "Income Tax",
        taxBracket: "Tax Bracket",
        month: "Month",
        total: "Total",
        average: "Average",
        grossRatio: "Ratio to Gross",
        taxBrackets: "{year} Income Tax Brackets",
        andAbove: "and above",
        note: "Note",
        minimumWageNote: "Cannot calculate salary below minimum wage",
        enterValidSalary: "Please enter a valid salary",
        salaryBelowMinimumWage: "Entered salary cannot be below minimum wage ({asgariUcret} TRY)",
        ceiling: "Ceiling",
        calculatedStampTax: "Calculated Stamp Tax",
        deductedStampTax: "Deducted Stamp Tax",
        calculatedIncomeTax: "Calculated Income Tax",
        deductedIncomeTax: "Deducted Income Tax",
        minimumWageExemption: "Minimum Wage Exemption",
        taxBracketDetails: "Tax Bracket Details",
        fromBracket: "from bracket",
        months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        disclaimer: "This calculation tool is for informational purposes only. Calculations are based on approximate values and may differ from actual values. Particularly, minimum wage exemption, tax brackets, and SGK ceiling amounts may change during the year. For accurate information, please consult your financial advisor or visit the official websites of relevant institutions (GİB, SGK). No responsibility is accepted for any damages that may arise from calculations made through this tool.",
        privacy: {
            title: "Privacy Policy",
            lastUpdated: "Last Updated: November 28, 2024",
            introduction: "Introduction",
            introductionText: "As a Net Salary Calculator tool, we value your privacy. This privacy policy provides information about how we use and protect your data when you use our site.",
            dataCollection: "Data Collection and Usage",
            salaryCalculations: "Salary Calculations",
            salaryCalculationsText: "All salary information you enter and calculations performed are processed locally in your browser. This information is not sent to our servers or stored in any way.",
            personalData: "Personal Data",
            personalDataText: "Our site does not collect or store any personal data. All operations are performed locally in your browser.",
            cookiesAndAnalytics: "Cookies and Analytics",
            necessaryCookies: "Necessary Cookies",
            necessaryCookiesText: "We use the minimum necessary cookies required for the site to function properly.",
            analyticCookies: "Analytics Cookies",
            analyticCookiesText: "Only with your consent, we collect anonymous usage statistics to help us understand how you use the site. This data contains no personal information and cannot be linked to you.",
            security: "Security",
            securityText: "Since all calculations are performed locally in your browser, your data is never transmitted over the internet or stored on our servers. This ensures maximum security of your information.",
            contact: "Contact",
            contactText: "If you have any questions about this privacy policy, please contact us.",
            close: "Close"
        },
        faq: {
            title: "Frequently Asked Questions",
            netSalaryCalc: {
                question: "How is net salary calculated in {year}?",
                answer: "For {year} net salary calculation, the following deductions are made from gross salary:\n\n" +
                       "1. Social Security Premium (14%)\n" +
                       "2. Unemployment Insurance (1%)\n" +
                       "3. Income Tax (15-40% based on bracket)\n" +
                       "4. Stamp Tax (0.759%)\n\n" +
                       "Minimum wage exemption is also included in the calculation."
            },
            taxBrackets: {
                question: "What are the {year} tax brackets?",
                answer: "Income tax brackets for {year} are as follows:\n\n" +
                       "• 0-70,000 TRY: 15%\n" +
                       "• 70,000-150,000 TRY: 20%\n" +
                       "• 150,000-550,000 TRY: 27%\n" +
                       "• Above 550,000 TRY: 35%"
            },
            minimumWage: {
                question: "What is the {year} minimum wage exemption?",
                answer: "In {year}, the amount equal to minimum wage is exempt from income tax. Thanks to this exemption, no income tax is calculated for the portion up to the minimum wage amount."
            },
            sgkPremium: {
                question: "What is SGK premium and how is it calculated?",
                answer: "SGK premium is a mandatory deduction of 14% from the employee's gross salary that is transferred to the social security system. For salaries exceeding the SGK ceiling, no deduction is made for the portion above the ceiling."
            },
            stampTax: {
                question: "What is stamp tax?",
                answer: "Stamp tax is a legal tax deducted at a rate of 0.759% from salary payments. It is calculated on gross salary and is deducted regularly every month."
            }
        },
        initialTableContent: {
            description: "Our net salary calculator automatically calculates all deductions and tax brackets from your gross salary. Enter your gross salary in the field above to start detailed calculation.",
            features: "Features",
            feature1: "Social security premium, unemployment insurance and stamp tax calculations",
            feature2: "Cumulative tax bracket calculation",
            feature3: "Current calculations including minimum wage exemption",
            feature4: "Detailed salary analysis on monthly and yearly basis"
        }
    },
    ar: {
        title: "حاسبة الراتب الصافي",
        subtitle: "حساب مفصل يشمل شرائح الضريبة لحالية وإعفاء الحد الأدنى للأجور",
        calculate: "احسب",
        grossSalary: "الراتب الإجمالي",
        grossSalaryPlaceholder: "أدخل راتبك الإجمالي الشهري",
        netSalary: "الراتب الصافي",
        sgkPremium: "قسط الضمان الاجتماعي",
        unemploymentInsurance: "تأمين البطالة",
        stampTax: "ضريبة الدمغة",
        incomeTax: "ضريبة الدخل",
        taxBracket: "شريحة الضريبة",
        month: "الشهر",
        total: "المجموع",
        average: "المتوسط",
        grossRatio: "النسبة إلى الإجمالي",
        taxBrackets: "شرائح ضريبة الدخل {year}",
        andAbove: "وما فوق",
        note: "ملاحظة",
        minimumWageNote: "لا يمكن حساب راتب أقل من الحد الأدنى للأجور",
        enterValidSalary: "الرجاء إدخال راتب صحيح",
        salaryBelowMinimumWage: "لا يمكن أن يكون الراتب المدخل أقل من الحد الأدنى للأجور ({asgariUcret} ليرة تركية)",
        ceiling: "الحد الأقصى",
        calculatedStampTax: "ضريبة الدمغة المحسوبة",
        deductedStampTax: "ضريبة الدمغة المخفضة",
        calculatedIncomeTax: "ضريبة الدخل المحسوبة",
        deductedIncomeTax: "ضريبة الدخل المخفضة",
        minimumWageExemption: "إعفاء الحد الأدنى للأجور",
        taxBracketDetails: "تفاصيل شريحة الضريبة",
        fromBracket: "من الشريحة",
        months: ["يناير", "فبراير", "مارس", "أبريل", "مايو", "يونيو", "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"],
        disclaimer: "هذه الأداة الحسابية هي لأغراض إعلامية فقط. تستند الحسابات إلى قيم تقريبية وقد تختلف عن القيم الفعلية. على وجه الخصوص، قد يتغير إعفاء الحد الأدنى للأجور وشرائح الضرائب والحد الأقصى للضمان الاجتماعي خلال العام. للحصول على معلومات دقيقة، يرجى استشارة مستشارك المالي أو زيارة المواقع الرسمية للمؤسسات ذات الصلة. لا نتحمل أي مسؤولية عن أي أضرار قد تنشأ عن الحسابات التي تتم من خلال هذه الأداة.",
        privacy: {
            title: "سياسة الخصوصية",
            lastUpdated: "آخر تحديث: 28 نوفمبر 2024",
            introduction: "مقدمة",
            introductionText: "كأداة لحساب الراتب الصافي، نحن نقدر خصوصيتك. توفر سياسة الخصوصية هذه معلومات حول كيفية استخدامنا وحماية بياناتك عند استخدام موقعنا.",
            dataCollection: "جمع البيانات واستخدامها",
            salaryCalculations: "حسابات الرواتب",
            salaryCalculationsText: "تتم معالجة جميع معلومات الراتب التي تدخلها والحسابات التي تتم محليًا في متصفحك. لا يتم إرسال هذه المعلومات إلى خوادمنا أو تخزينها بأي شكل من الأشكال.",
            personalData: "البيانات الشخصية",
            personalDataText: "موقعنا لا يجمع أو يخزن أي بيانات شخصية. تتم جميع العمليات محليًا في متصفحك.",
            cookiesAndAnalytics: "ملفات تعريف الارتباط والتحليلات",
            necessaryCookies: "ملفات تعريف الارتباط الضرورية",
            necessaryCookiesText: "نستخدم الحد الأدنى من ملفات تعريف الارتباط الضرورية المطلوبة لعمل الموقع بشكل صحيح.",
            analyticCookies: "ملفات تعريف الارتباط التحليلية",
            analyticCookiesText: "فقط بموافقتك، نجمع إحصائيات استخدام مجهولة لمساعدتنا على فهم كيفية استخدامك للموقع. لا تحتوي هذه البيانات على معلومات شخصية ولا يمكن ربطها بك.",
            security: "الأمان",
            securityText: "نظرًا لأن جميع الحسابات تتم محليًا في متصفحك، فلا يتم نقل بياناتك عبر الإنترنت أو تخزينها على خوادمنا. هذا يضمن أقصى درجات الأمان لمعلوماتك.",
            contact: "اتصل بنا",
            contactText: "إذا كان لديك أي أسئلة حول سياسة الخصوصية هذه، يرجى الاتصال بنا.",
            close: "إغلاق"
        },
        faq: {
            title: "الأسئلة الشائعة",
            netSalaryCalc: {
                question: "كيف يتم حساب الراتب الصافي في عام {year}؟",
                answer: "لحساب الراتب الصافي لعام {year}، يتم خصم ما يلي من الراتب الإجمالي:\n\n" +
                       "1. قسط الضمان الاجتماعي (14%)\n" +
                       "2. تأمين البطالة (1%)\n" +
                       "3. ضريبة الدخل (15-40% حسب الشريحة)\n" +
                       "4. ضريبة الدمغة (0.759%)\n\n" +
                       "يتم تضمين إعفاء الحد الأدنى للأجور في الحساب."
            },
            taxBrackets: {
                question: "ما هي شرائح الضريبة لعام {year}؟",
                answer: "شرائح ضريبة الدخل لعام {year} هي كما يلي:\n\n" +
                       "• 0-70,000 ليرة تركية: 15%\n" +
                       "• 70,000-150,000 ليرة تركية: 20%\n" +
                       "• 150,000-550,000 ليرة تركية: 27%\n" +
                       "• أكثر من 550,000 ليرة تركية: 35%"
            },
            minimumWage: {
                question: "ما هو إعفاء الحد الأدنى للأجور لعام {year}؟",
                answer: "في عام {year}، يتم إعفاء المبلغ المساوي للحد الأدنى للأجور من ضريبة الدخل. بفضل هذا الإعفاء، لا يتم احتساب ضريبة الدخل للجزء حتى مبلغ الحد الأدنى للأجور."
            },
            sgkPremium: {
                question: "ما هو قسط الضمان الاجتماعي وكيف يتم حسابه؟",
                answer: "قسط الضمان الاجتماعي هو خصم إلزامي بنسبة 14% من الراتب الإجمالي للموظف يتم تحويله إلى نظام الضمان الاجتماعي. للرواتب التي تتجاوز سقف الضمان الاجتماعي، لا يتم إجراء خصم للجزء الذي يتجاوز السقف."
            },
            stampTax: {
                question: "ما هي ضريبة الدمغة؟",
                answer: "ضريبة الدمغة هي ضريبة قانونية تخصم بنسبة 0.759% من مدفوعات الرواتب. يتم حسابها على الراتب الإجمالي ويتم خصمها بشكل منتظم كل شهر."
            }
        },
        initialTableContent: {
            description: "تقوم حاسبة الراتب الصافي لدينا تلقائيًا بحساب جميع الخصومات وشرائح الضريبة من راتبك الإجمالي. أدخل راتبك الإجمالي في الحقل أعلاه لبدء الحساب التفصيلي.",
            features: "الميزات",
            feature1: "حسابات قسط الضمان الاجتماعي وتأمين البطالة وضريبة الدمغة",
            feature2: "حساب شريحة الضريبة التراكمية",
            feature3: "الحسابات الحالية بما في ذلك إعفاء الحد الأدنى للأجور",
            feature4: "تحليل تفصيلي للراتب على أساس شهري وسنوي"
        }
    }
}; 