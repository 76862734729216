import React from 'react';
import { 
    Paper, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow,
    TextField,
    IconButton,
    Box,
    Typography,
    useTheme
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useSalary } from '../context/SalaryContext';
import { 
    hesaplaSGKPrimi, 
    vergiDilimleriniHesapla, 
    hesaplaGelirVergisi,
    hesaplaVergiIstisnasi,
    hesaplaVergiDilimiDetaylari
} from '../utils/calculations';
import { CustomTooltip } from './CustomTooltip';

export function SalaryTable() {
    const { 
        t, 
        selectedYear, 
        TAX_CONFIG, 
        monthlyGrossSalaries,
        setMonthlyGrossSalaries,
        formatPara,
        isStickyMonthsEnabled
    } = useSalary();

    const theme = useTheme();
    const currentConfig = TAX_CONFIG[selectedYear];
    const months = t('months');

    const handleMonthlySalaryEdit = (event, monthIndex) => {
        const value = event.target.value;
        const newSalaries = [...monthlyGrossSalaries];
        
        // Allow empty value
        if (value === '') {
            newSalaries[monthIndex] = 0;
            setMonthlyGrossSalaries(newSalaries);
            return;
        }

        // Remove leading zeros
        const cleanValue = value.replace(/^0+/, '');
        const numericValue = parseFloat(cleanValue) || 0;
        newSalaries[monthIndex] = numericValue;
        setMonthlyGrossSalaries(newSalaries);
    };

    let kumulatifMatrah = 0;
    let asgariUcretKumulatifMatrah = 0;
    let totals = {
        brutMaas: 0,
        sgk: 0,
        issizlik: 0,
        damgaVergisi: 0,
        gelirVergisi: 0,
        netMaas: 0
    };

    const rows = months.map((ay, index) => {
        const brutMaas = monthlyGrossSalaries[index];
        
        if (brutMaas <= 0) {
            return {
                ay,
                brutMaas: brutMaas,
                sgkKesintisi: 0,
                issizlikSigortasi: 0,
                damgaVergisi: 0,
                vergiDilimleri: '',
                gelirVergisi: 0,
                netMaas: 0,
                sgkTavanInfo: '',
                damgaVergiTooltip: '',
                gelirVergiTooltip: '',
                index
            };
        }

        if (brutMaas < currentConfig.asgariUcret) {
            return {
                ay,
                brutMaas: brutMaas,
                sgkKesintisi: 0,
                issizlikSigortasi: 0,
                damgaVergisi: 0,
                vergiDilimleri: '',
                gelirVergisi: 0,
                netMaas: 0,
                sgkTavanInfo: t('salaryBelowMinimumWage', { asgariUcret: formatPara(currentConfig.asgariUcret) }),
                damgaVergiTooltip: '',
                gelirVergiTooltip: '',
                index
            };
        }

        const sgkKesintisi = hesaplaSGKPrimi(brutMaas, currentConfig.katsayilar.sgkOrani, currentConfig.sgkTavanUcret);
        const issizlikSigortasi = hesaplaSGKPrimi(brutMaas, currentConfig.katsayilar.issizlikSigortasiOrani, currentConfig.sgkTavanUcret);
        const aylikMatrah = brutMaas - (sgkKesintisi + issizlikSigortasi);
        
        const asgariUcretSgkKesintisi = currentConfig.asgariUcret * currentConfig.katsayilar.sgkOrani;
        const asgariUcretIssizlikKesintisi = currentConfig.asgariUcret * currentConfig.katsayilar.issizlikSigortasiOrani;
        const asgariUcretAylikMatrah = currentConfig.asgariUcret - (asgariUcretSgkKesintisi + asgariUcretIssizlikKesintisi);
        
        const vergiDilimleri = vergiDilimleriniHesapla(aylikMatrah, kumulatifMatrah + aylikMatrah, currentConfig.vergiDilimleri);
        const hesaplananGelirVergisi = hesaplaGelirVergisi(aylikMatrah, kumulatifMatrah + aylikMatrah, currentConfig.vergiDilimleri);
        const hesaplananDamgaVergisi = brutMaas * currentConfig.katsayilar.damgaVergisiOrani;

        const vergiDilimiDetaylari = hesaplaVergiDilimiDetaylari(aylikMatrah, kumulatifMatrah + aylikMatrah, currentConfig.vergiDilimleri);
        
        const gelirVergisiIstisnasi = hesaplaVergiIstisnasi(
            brutMaas, 
            aylikMatrah, 
            hesaplananGelirVergisi, 
            'gelir', 
            index, 
            currentConfig.asgariUcret, 
            currentConfig.vergiDilimleri,
            asgariUcretKumulatifMatrah + asgariUcretAylikMatrah
        );
        const damgaVergisiIstisnasi = hesaplaVergiIstisnasi(
            brutMaas, 
            aylikMatrah, 
            hesaplananDamgaVergisi, 
            'damga', 
            index, 
            currentConfig.asgariUcret
        );

        const gelirVergisi = Math.max(0, hesaplananGelirVergisi - gelirVergisiIstisnasi);
        const damgaVergisi = Math.max(0, hesaplananDamgaVergisi - damgaVergisiIstisnasi);
        
        kumulatifMatrah += aylikMatrah;
        asgariUcretKumulatifMatrah += asgariUcretAylikMatrah;

        const netMaas = brutMaas - (sgkKesintisi + issizlikSigortasi + gelirVergisi + damgaVergisi);

        // Update totals
        totals.brutMaas += brutMaas;
        totals.sgk += sgkKesintisi;
        totals.issizlik += issizlikSigortasi;
        totals.damgaVergisi += damgaVergisi;
        totals.gelirVergisi += gelirVergisi;
        totals.netMaas += netMaas;

        const sgkTavanInfo = brutMaas > currentConfig.sgkTavanUcret ? 
            `(${t('ceiling')}: ${formatPara(currentConfig.sgkTavanUcret)})` : '';

        const damgaVergiTooltip = `${ay} ${selectedYear} ${t('calculatedStampTax')}:\n${formatPara(hesaplananDamgaVergisi)} TL\n-\n${ay} ${selectedYear} ${t('deductedStampTax')}:\n${formatPara(damgaVergisiIstisnasi)} TL\n(${t('minimumWageExemption')})`.trim();

        let vergiDilimiDetayMetni = '';
        if (vergiDilimiDetaylari.length > 1) {
            vergiDilimiDetayMetni = `\n-\n${t('taxBracketDetails')}:`;
            vergiDilimiDetaylari.forEach(detay => {
                vergiDilimiDetayMetni += `\n%${detay.oran * 100} ${t('fromBracket')}: ${formatPara(detay.matrah)} TL × %${detay.oran * 100} = ${formatPara(detay.vergi)} TL`;
            });
        }

        const gelirVergiTooltip = `${ay} ${selectedYear} ${t('calculatedIncomeTax')}:\n${formatPara(hesaplananGelirVergisi)} TL\n-\n${ay} ${selectedYear} ${t('deductedIncomeTax')}:\n${formatPara(gelirVergisiIstisnasi)} TL\n(${t('minimumWageExemption')})${vergiDilimiDetayMetni}`.trim();

        return {
            ay,
            brutMaas,
            sgkKesintisi,
            issizlikSigortasi,
            damgaVergisi,
            vergiDilimleri,
            gelirVergisi,
            netMaas,
            sgkTavanInfo,
            damgaVergiTooltip,
            gelirVergiTooltip,
            index
        };
    });

    // Calculate averages
    const averages = {
        brutMaas: totals.brutMaas / 12,
        sgk: totals.sgk / 12,
        issizlik: totals.issizlik / 12,
        damgaVergisi: totals.damgaVergisi / 12,
        vergiDilimleri: rows.reduce((acc, row) => {
            const match = row.vergiDilimleri?.match(/\d+/);
            return match ? acc + parseInt(match[0], 10) : acc;
        }, 0) / rows.filter(row => row.vergiDilimleri).length,
        gelirVergisi: totals.gelirVergisi / 12,
        netMaas: totals.netMaas / 12
    };

    // Calculate percentages
    const percentages = {
        brutMaas: 100,
        sgk: (averages.sgk / averages.brutMaas * 100),
        issizlik: (averages.issizlik / averages.brutMaas * 100),
        damgaVergisi: (averages.damgaVergisi / averages.brutMaas * 100),
        vergiDilimleri: averages.vergiDilimleri,
        gelirVergisi: (averages.gelirVergisi / averages.brutMaas * 100),
        netMaas: 100 - ((averages.sgk / averages.brutMaas * 100) + 
                       (averages.issizlik / averages.brutMaas * 100) + 
                       (averages.damgaVergisi / averages.brutMaas * 100) + 
                       (averages.gelirVergisi / averages.brutMaas * 100))
    };

    // Hesaplama yapılmadan önce gösterilecek içerik
    if (!monthlyGrossSalaries || monthlyGrossSalaries.every(salary => !salary)) {
        return (
            <Box sx={{ p: 2 }}>
                <Typography variant="body1" gutterBottom>
                    {t('initialTableContent.description')}
                </Typography>
                <Box sx={{ my: 2 }}>
                    <Typography variant="body1" component="div">
                        {t('initialTableContent.features')}:
                        <ul>
                            <li>{t('initialTableContent.feature1')}</li>
                            <li>{t('initialTableContent.feature2')}</li>
                            <li>{t('initialTableContent.feature3')}</li>
                            <li>{t('initialTableContent.feature4')}</li>
                        </ul>
                    </Typography>
                </Box>
            </Box>
        );
    }

    return (
        <TableContainer 
            component={Paper} 
            sx={{ 
                margin: '20px auto',
                width: '100%',
                overflowX: {
                    xs: 'auto',
                    md: 'hidden'
                },
                '& .MuiTable-root': {
                    width: '100%',
                    minWidth: {
                        xs: 650,
                        sm: 800,
                        md: '100%'
                    }
                },
                '& .MuiTableCell-root': {
                    padding: {
                        xs: '6px 4px',
                        sm: '8px 6px',
                        md: '12px 8px'
                    },
                    fontSize: {
                        xs: '0.75rem',
                        sm: '0.875rem',
                        md: '1rem'
                    },
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                },
                '& .MuiTableCell-head': {
                    fontWeight: 'bold',
                    backgroundColor: theme.palette.background.paper,
                    position: 'sticky',
                    top: 0,
                    zIndex: 1
                },
                '& .MuiTableCell-sizeSmall': {
                    padding: {
                        xs: '4px 2px',
                        sm: '6px 4px',
                        md: '8px 6px'
                    }
                },
                '& input[type="number"]': {
                    width: {
                        xs: '60px',
                        sm: '80px',
                        md: '100px'
                    },
                    padding: {
                        xs: '4px',
                        sm: '6px',
                        md: '8px'
                    }
                }
            }}
        >
            <Table 
                size="small" 
                aria-label="salary table"
                sx={{
                    '& td:first-of-type, & th:first-of-type': {
                        position: 'sticky',
                        left: 0,
                        backgroundColor: theme.palette.background.paper,
                        zIndex: 2
                    },
                    '& .MuiTableCell-head': {
                        height: 'auto !important',
                        padding: '8px 4px',
                        fontSize: { xs: '12px', sm: '14px' },
                        lineHeight: 1.2,
                        '& > div': {
                            wordBreak: 'normal',
                            whiteSpace: 'normal',
                            display: '-webkit-box',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }
                    }
                }}
            >
                <TableHead>
                    <TableRow>
                        <TableCell 
                            sx={{ 
                                position: isStickyMonthsEnabled ? 'sticky' : 'static',
                                left: 0,
                                backgroundColor: theme.palette.background.paper,
                                zIndex: 2,
                                width: { xs: '60px', sm: '80px', md: '100px' }
                            }}
                        >
                            <div>{t('month')}</div>
                        </TableCell>
                        {[
                            'grossSalary',
                            'sgkPremium',
                            'unemploymentInsurance',
                            'stampTax',
                            'taxBracket',
                            'incomeTax',
                            'netSalary'
                        ].map((key) => (
                            <TableCell 
                                key={key}
                                sx={{ 
                                    width: { xs: '80px', sm: '100px', md: '120px' }
                                }}
                            >
                                <div>{t(key)}</div>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map(row => (
                        <TableRow key={row.ay}>
                            <TableCell
                                sx={{ 
                                    position: isStickyMonthsEnabled ? 'sticky' : 'static',
                                    left: 0,
                                    backgroundColor: theme.palette.background.paper,
                                    zIndex: 1
                                }}
                            >
                                {row.ay}
                            </TableCell>
                            <TableCell>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                    <TextField
                                        type="number"
                                        value={row.brutMaas === 0 ? '' : row.brutMaas}
                                        onChange={(e) => handleMonthlySalaryEdit(e, row.index)}
                                        size="small"
                                        variant="standard"
                                        inputProps={{
                                            min: 0,
                                            step: "any"
                                        }}
                                    />
                                    {row.sgkTavanInfo && (
                                        <Typography variant="caption" color="text.secondary">
                                            {row.sgkTavanInfo}
                                        </Typography>
                                    )}
                                </Box>
                            </TableCell>
                            <TableCell>{formatPara(row.sgkKesintisi)}</TableCell>
                            <TableCell>{formatPara(row.issizlikSigortasi)}</TableCell>
                            <TableCell>
                                {formatPara(row.damgaVergisi)}
                                <CustomTooltip title={row.damgaVergiTooltip}>
                                    <IconButton size="small">
                                        <InfoIcon fontSize="small" />
                                    </IconButton>
                                </CustomTooltip>
                            </TableCell>
                            <TableCell>{row.vergiDilimleri}</TableCell>
                            <TableCell>
                                {formatPara(row.gelirVergisi)}
                                <CustomTooltip title={row.gelirVergiTooltip}>
                                    <IconButton size="small">
                                        <InfoIcon fontSize="small" />
                                    </IconButton>
                                </CustomTooltip>
                            </TableCell>
                            <TableCell>{formatPara(row.netMaas)}</TableCell>
                        </TableRow>
                    ))}
                    <TableRow sx={{ backgroundColor: theme.palette.action.hover }}>
                        <TableCell
                            sx={{ 
                                position: isStickyMonthsEnabled ? 'sticky' : 'static',
                                left: 0,
                                backgroundColor: theme.palette.action.hover,
                                fontWeight: 'bold'
                            }}
                        >
                            {t('total')}
                        </TableCell>
                        <TableCell>{formatPara(totals.brutMaas)}</TableCell>
                        <TableCell>{formatPara(totals.sgk)}</TableCell>
                        <TableCell>{formatPara(totals.issizlik)}</TableCell>
                        <TableCell>{formatPara(totals.damgaVergisi)}</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>{formatPara(totals.gelirVergisi)}</TableCell>
                        <TableCell>{formatPara(totals.netMaas)}</TableCell>
                    </TableRow>
                    <TableRow sx={{ backgroundColor: theme.palette.mode === 'light' ? '#e3f2fd' : theme.palette.primary.dark }}>
                        <TableCell
                            sx={{ 
                                position: isStickyMonthsEnabled ? 'sticky' : 'static',
                                left: 0,
                                backgroundColor: theme.palette.mode === 'light' ? '#e3f2fd' : theme.palette.primary.dark,
                                color: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.87)' : theme.palette.primary.contrastText,
                                fontWeight: 'bold'
                            }}
                        >
                            {t('average')}
                        </TableCell>
                        {Object.keys(averages).map(key => (
                            <TableCell 
                                key={key} 
                                sx={{ color: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.87)' : theme.palette.primary.contrastText }}
                            >
                                {isNaN(averages[key]) || averages[key] === '-' ? '-' : 
                                 key === 'vergiDilimleri' ? `%${formatPara(averages[key])}` : formatPara(averages[key])}
                            </TableCell>
                        ))}
                    </TableRow>
                    <TableRow sx={{ backgroundColor: theme.palette.mode === 'light' ? '#e8f5e9' : theme.palette.success.dark }}>
                        <TableCell
                            sx={{ 
                                position: isStickyMonthsEnabled ? 'sticky' : 'static',
                                left: 0,
                                backgroundColor: theme.palette.mode === 'light' ? '#e8f5e9' : theme.palette.success.dark,
                                color: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.87)' : theme.palette.success.contrastText,
                                fontWeight: 'bold'
                            }}
                        >
                            {t('grossRatio')}
                        </TableCell>
                        {Object.keys(percentages).map(key => (
                            <TableCell 
                                key={key}
                                sx={{ color: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.87)' : theme.palette.success.contrastText }}
                            >
                                {isNaN(percentages[key]) || percentages[key] === '-' ? '-' : 
                                 key === 'vergiDilimleri' ? `%${formatPara(percentages[key])}` : `%${formatPara(percentages[key])}`}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
} 