export function hesaplaSGKPrimi(brutMaas, oran, sgkTavanUcret) {
    const tavanUstundekiMaas = Math.max(0, brutMaas - sgkTavanUcret);
    const sgkMatrahi = brutMaas - tavanUstundekiMaas;
    return sgkMatrahi * oran;
}

export function vergiDilimleriniHesapla(aylikMatrah, kumulatifMatrah, vergiDilimleri) {
    let vergiDilimleriKullanilan = [];
    let kalanMatrah = aylikMatrah;
    let oncekiKumulatif = kumulatifMatrah - aylikMatrah;
    let oncekiLimit = 0;

    for (let i = 0; i < vergiDilimleri.length && kalanMatrah > 0; i++) {
        const dilim = vergiDilimleri[i];
        const limit = dilim.limit === null ? Infinity : dilim.limit;
        
        if (oncekiKumulatif >= limit) {
            continue;
        }
        
        let dilimMatrahi;
        if (oncekiKumulatif > oncekiLimit) {
            const dilimTavani = limit - oncekiKumulatif;
            dilimMatrahi = Math.min(kalanMatrah, dilimTavani);
        } else {
            const dilimTavani = limit - oncekiLimit;
            dilimMatrahi = Math.min(kalanMatrah, dilimTavani);
        }

        if (dilimMatrahi > 0) {
            vergiDilimleriKullanilan.push(dilim.oran * 100);
            kalanMatrah -= dilimMatrahi;
        }
        
        oncekiLimit = limit;
    }

    if (vergiDilimleriKullanilan.length === 0) {
        return '';
    } else if (vergiDilimleriKullanilan.length === 1) {
        return `%${vergiDilimleriKullanilan[0]}`;
    } else {
        return `%${vergiDilimleriKullanilan[0]}-${vergiDilimleriKullanilan[vergiDilimleriKullanilan.length - 1]}`;
    }
}

export function hesaplaVergiDilimiDetaylari(aylikMatrah, kumulatifMatrah, vergiDilimleri) {
    let detaylar = [];
    let kalanMatrah = aylikMatrah;
    let oncekiKumulatif = kumulatifMatrah - aylikMatrah;
    let oncekiLimit = 0;

    for (let i = 0; i < vergiDilimleri.length && kalanMatrah > 0; i++) {
        const dilim = vergiDilimleri[i];
        const limit = dilim.limit === null ? Infinity : dilim.limit;
        
        let dilimMatrahi;
        if (oncekiKumulatif >= limit) {
            continue;
        } else if (oncekiKumulatif > oncekiLimit) {
            const dilimTavani = limit - oncekiKumulatif;
            dilimMatrahi = Math.min(kalanMatrah, dilimTavani);
        } else {
            const dilimTavani = limit - oncekiLimit;
            dilimMatrahi = Math.min(kalanMatrah, dilimTavani);
        }

        if (dilimMatrahi > 0) {
            detaylar.push({
                oran: dilim.oran,
                matrah: dilimMatrahi,
                vergi: dilimMatrahi * dilim.oran
            });
            kalanMatrah -= dilimMatrahi;
        }
        
        oncekiLimit = limit;
    }

    return detaylar;
}

export function hesaplaGelirVergisi(aylikMatrah, kumulatifMatrah, vergiDilimleri) {
    const detaylar = hesaplaVergiDilimiDetaylari(aylikMatrah, kumulatifMatrah, vergiDilimleri);
    return detaylar.reduce((toplam, detay) => toplam + detay.vergi, 0);
}

export function hesaplaVergiIstisnasi(brutMaas, aylikMatrah, hesaplananVergi, vergiTipi, ay, asgariUcret, vergiDilimleri, asgariUcretKumulatifMatrah) {
    if (brutMaas <= asgariUcret) {
        return hesaplananVergi;
    }

    if (vergiTipi === 'damga') {
        // Damga vergisi için asgari ücret oranında istisna
        const asgariUcretOrani = asgariUcret / brutMaas;
        return hesaplananVergi * asgariUcretOrani;
    } else if (vergiTipi === 'gelir') {
        // Asgari ücrete denk gelen gelir vergisini hesapla
        const asgariUcretSgkKesintisi = asgariUcret * 0.14; // SGK işçi payı
        const asgariUcretIssizlikKesintisi = asgariUcret * 0.01; // İşsizlik sigortası
        const asgariUcretAylikMatrah = asgariUcret - (asgariUcretSgkKesintisi + asgariUcretIssizlikKesintisi);
        
        // Asgari ücret için vergi hesapla (kümülatif matrahı kullanarak)
        const asgariUcretVergiDetaylari = hesaplaVergiDilimiDetaylari(
            asgariUcretAylikMatrah, 
            asgariUcretKumulatifMatrah, 
            vergiDilimleri
        );
        const asgariUcretVergisi = asgariUcretVergiDetaylari.reduce((toplam, detay) => toplam + detay.vergi, 0);
        
        return asgariUcretVergisi;
    }
    
    return 0;
}

export function getSalaryRange(salary) {
    if (salary < 10000) return '0-10k';
    if (salary < 20000) return '10k-20k';
    if (salary < 30000) return '20k-30k';
    if (salary < 50000) return '30k-50k';
    if (salary < 100000) return '50k-100k';
    return '100k+';
} 