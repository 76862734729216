import { createTheme } from '@mui/material/styles';

const getDesignTokens = (mode) => ({
    palette: {
        mode,
        ...(mode === 'light'
            ? {
                // Light mode
                primary: {
                    main: '#1976d2',
                    light: '#42a5f5',
                    dark: '#1565c0',
                    contrastText: 'rgba(0, 0, 0, 0.87)',
                },
                secondary: {
                    main: '#9c27b0',
                    light: '#ba68c8',
                    dark: '#7b1fa2',
                    contrastText: '#ffffff',
                },
                success: {
                    main: '#2e7d32',
                    light: '#4caf50',
                    dark: '#1b5e20',
                    contrastText: 'rgba(0, 0, 0, 0.87)',
                },
                warning: {
                    main: '#ed6c02',
                    light: '#fff3e0',
                    dark: '#e65100',
                    contrastText: 'rgba(0, 0, 0, 0.87)',
                },
                background: {
                    default: '#f5f5f5',
                    paper: '#ffffff',
                },
                text: {
                    primary: 'rgba(0, 0, 0, 0.87)',
                    secondary: 'rgba(0, 0, 0, 0.6)',
                },
                divider: 'rgba(0, 0, 0, 0.12)',
                action: {
                    hover: 'rgba(0, 0, 0, 0.04)',
                    selected: 'rgba(0, 0, 0, 0.08)',
                },
            }
            : {
                // Dark mode
                primary: {
                    main: '#90caf9',
                    light: '#e3f2fd',
                    dark: '#42a5f5',
                    contrastText: '#ffffff',
                },
                secondary: {
                    main: '#ce93d8',
                    light: '#f3e5f5',
                    dark: '#ab47bc',
                    contrastText: '#ffffff',
                },
                success: {
                    main: '#66bb6a',
                    light: '#81c784',
                    dark: '#388e3c',
                    contrastText: '#ffffff',
                },
                warning: {
                    main: '#ffa726',
                    light: '#ffb74d',
                    dark: '#f57c00',
                    contrastText: '#ffffff',
                },
                background: {
                    default: '#121212',
                    paper: '#1e1e1e',
                },
                text: {
                    primary: '#ffffff',
                    secondary: 'rgba(255, 255, 255, 0.7)',
                },
                divider: 'rgba(255, 255, 255, 0.12)',
                action: {
                    hover: 'rgba(255, 255, 255, 0.08)',
                    selected: 'rgba(255, 255, 255, 0.16)',
                },
            }),
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    transition: 'background-color 0.3s ease-in-out',
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: ({ theme }) => ({
                    borderBottom: `1px solid ${theme.palette.divider}`,
                }),
            },
        },
    },
});

export const getTheme = (mode) => {
    return createTheme(getDesignTokens(mode));
}; 