import React from 'react';
import { Paper, Typography, Box } from '@mui/material';
import { useSalary } from '../context/SalaryContext';

export function TaxBrackets() {
    const { t, selectedYear, TAX_CONFIG, formatPara } = useSalary();
    const currentConfig = TAX_CONFIG[selectedYear];

    return (
        <Paper 
            elevation={1}
            sx={{ 
                padding: 3,
                borderRadius: 1
            }}
        >
            <Typography 
                variant="h6" 
                gutterBottom
                sx={{ fontWeight: 500 }}
            >
                {t('taxBrackets', { year: selectedYear })}
            </Typography>
            
            {currentConfig.vergiDilimleri.map((dilim, index) => (
                <Box key={index} sx={{ marginY: 1 }}>
                    {index === 0 ? (
                        <Typography variant="body1">
                            0 - {formatPara(dilim.limit)} TL: %{dilim.oran * 100}
                        </Typography>
                    ) : dilim.limit === null ? (
                        <Typography variant="body1">
                            {formatPara(currentConfig.vergiDilimleri[index-1].limit)} TL {t('andAbove')}: %{dilim.oran * 100}
                        </Typography>
                    ) : (
                        <Typography variant="body1">
                            {formatPara(currentConfig.vergiDilimleri[index-1].limit)} TL - {formatPara(dilim.limit)} TL: %{dilim.oran * 100}
                        </Typography>
                    )}
                </Box>
            ))}

            <Typography 
                sx={{ 
                    marginTop: 2,
                    color: 'text.secondary'
                }}
            >
                <strong>{t('note')}:</strong> {t('minimumWageNote')} ({formatPara(currentConfig.asgariUcret)} TL)
            </Typography>
        </Paper>
    );
} 