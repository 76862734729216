import React, { useState } from 'react';
import { TextField, Button, Box } from '@mui/material';
import { useSalary } from '../context/SalaryContext';

export function SalaryInput() {
    const { t, selectedYear, TAX_CONFIG, setMonthlyGrossSalaries } = useSalary();
    const [error, setError] = useState('');
    const [grossSalary, setGrossSalary] = useState('');

    const handleCalculate = () => {
        const salary = parseFloat(grossSalary);
        const config = TAX_CONFIG[selectedYear];
        
        if (!salary || salary <= 0) {
            setError(t('enterValidSalary'));
            return;
        }

        if (salary < config.asgariUcret) {
            setError(t('salaryBelowMinimumWage', { asgariUcret: config.asgariUcret }));
            return;
        }

        setError('');
        setMonthlyGrossSalaries(new Array(12).fill(salary));
    };

    return (
        <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', sm: 'row' }, 
            gap: { xs: 1, sm: 2 },
            alignItems: { xs: 'stretch', sm: 'flex-start' },
            width: '100%'
        }}>
            <TextField
                label={t('grossSalaryPlaceholder')}
                placeholder={t('grossSalaryPlaceholder')}
                type="number"
                value={grossSalary}
                onChange={(e) => setGrossSalary(e.target.value)}
                error={!!error}
                helperText={error}
                fullWidth
                sx={{ 
                    flexGrow: 1,
                    '& .MuiInputBase-input': {
                        fontSize: { xs: '1rem', sm: '1.1rem' },
                        padding: '14px'
                    },
                    '& .MuiInputBase-root': {
                        height: '56px'
                    }
                }}
                variant="outlined"
                size="medium"
            />
            <Button 
                variant="contained" 
                onClick={handleCalculate}
                sx={{ 
                    minWidth: { xs: '100%', sm: '120px' },
                    height: '56px',
                    fontSize: { xs: '1rem', sm: '1.1rem' }
                }}
            >
                {t('calculate')}
            </Button>
        </Box>
    );
} 