import React from 'react';
import { FormControl, Select, MenuItem } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import { useSalary } from '../context/SalaryContext';

const languages = [
    { code: 'tr', name: 'Türkçe' },
    { code: 'en', name: 'English' },
    { code: 'ar', name: 'العربية' }
];

export function LanguageSelector() {
    const { currentLanguage, setCurrentLanguage } = useSalary();

    const handleLanguageChange = (event) => {
        const newLang = event.target.value;
        setCurrentLanguage(newLang);
        document.documentElement.lang = newLang;
        document.body.dir = newLang === 'ar' ? 'rtl' : 'ltr';
    };

    return (
        <FormControl 
            variant="outlined" 
            size="small"
            sx={{
                minWidth: 'unset',
                '& .MuiPaper-root': {
                    zIndex: 100000
                }
            }}
        >
            <Select
                value={currentLanguage}
                onChange={handleLanguageChange}
                MenuProps={{
                    sx: {
                        zIndex: 100000
                    }
                }}
                sx={{ 
                    minWidth: 'unset',
                    padding: 0,
                    margin: 0,
                    '& .MuiSelect-select': {
                        padding: '0 !important',
                        width: '40px',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent'
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(0, 0, 0, 0.23)'
                    }
                }}
                IconComponent={() => null}
                renderValue={() => <LanguageIcon />}
            >
                {languages.map(lang => (
                    <MenuItem key={lang.code} value={lang.code}>
                        {lang.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
} 