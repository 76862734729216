import React from 'react';
import { FormControl, Select, MenuItem } from '@mui/material';
import { useSalary } from '../context/SalaryContext';

export function YearSelector({ onChange }) {
    const { selectedYear, setSelectedYear, TAX_CONFIG } = useSalary();

    const handleYearChange = (event) => {
        const newYear = event.target.value;
        setSelectedYear(newYear);
        if (onChange) {
            onChange(newYear);
        }
    };

    return (
        <FormControl variant="outlined" size="small">
            <Select
                value={selectedYear}
                onChange={handleYearChange}
                sx={{ minWidth: 120 }}
            >
                {Object.keys(TAX_CONFIG)
                    .sort()
                    .reverse()
                    .map(year => (
                        <MenuItem key={year} value={year}>
                            {year}
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    );
} 